export const objectToURIQuery = (obj = {}) => {
  return Object.keys(obj)
    .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(obj[k]))
    .join('&')
}

export const formatURI = (baseUrl, path, query = {}) => {
  return baseUrl + path + '?' + objectToURIQuery(query)
}

export const formatDate = (val) => {
  return val.replace(/(^|\s)([a-z])/g,
    function (m, p1, p2) {
      return p1 + p2.toUpperCase()
    })
}
