import PropTypes from 'prop-types'
import classNames from 'classnames'

import styles from '../../../styles/bantrab/components/typography.module.scss'

const Typography = ({ children, tag, weight, size, clamp, ...attrs }) => {
  const { className } = attrs
  attrs.className = classNames(className, {
    [styles.typography]: true,
    [styles[weight]]: weight,
    [styles[size]]: size,
    [styles.truncate]: clamp === 1
  })

  if (clamp === 1) {
    const { style = {} } = attrs
    style.minWidth = 0

    attrs.style = style
  }

  if (clamp > 1) {
    const { style = {} } = attrs
    style.display = '-webkit-box'
    style.overflow = 'hidden'
    style.textPverflow = 'ellipsis'
    style.WebkitBoxOrient = 'vertical'
    style.WebkitLineClamp = clamp

    attrs.style = style
  }

  const TagName = tag
  return (
    <TagName {...attrs}>
      { children}
    </TagName>
  )
}

Typography.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  weight: PropTypes.oneOf(['regular', 'medium', 'bold', 'black']),
  size: PropTypes.string,
  tag: PropTypes.string,
  clamp: PropTypes.number
}

Typography.defaultProps = {
  tag: 'div',
  weight: 'regular',
  size: null
}

export default Typography
