import PropTypes from 'prop-types'

const Icon = ({ size, height, width, svg, ...attrs }) => {
  const defaultSize = '1em'
  if (!height || !width) {
    size = defaultSize
  } else if (typeof height === 'number' || typeof width === 'number') {
    size = `${size}px`
  }
  const { style = {} } = attrs
  attrs.style = { ...style, height: height, width: width, size: size }
  const TagName = svg

  return <TagName {...attrs} height={size} width={size} />
}

Icon.propTypes = {
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  svg: PropTypes.oneOfType([PropTypes.node, PropTypes.element, PropTypes.func])
}

export default Icon
