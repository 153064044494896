import PropTypes from 'prop-types'
import { Parser as HTML2ReactParser } from 'html-to-react'

import BaseHead from 'next/head'

const GOOGLE_ANALITYCS_ID = process.env.NEXT_PUBLIC_BANTRAB_GOOGLE_ANALITYCS_ID

function Head ({ title, rawSeo, settings }) {
  const HTMLParser = new HTML2ReactParser()
  const RawSEOMeta = HTMLParser.parse(rawSeo)
  const googleAnalytics = HTMLParser.parse(
    `
      <!-- Global site tag (gtag.js) - Google Analytics -->
      <script async src="https://www.googletagmanager.com/gtag/js?id=${GOOGLE_ANALITYCS_ID}"></script>
      <script>
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());

        gtag('config', '${GOOGLE_ANALITYCS_ID}');
      </script>
    `
  )
  return (
    <BaseHead>
      <title>{title}</title>
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />

      <link rel="icon" href="/favicon.ico" />
      <link rel="icon" type="image/png" href="/favicon.png"/>
      <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png"/>
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png"/>
      <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png"/>

      {
        settings && settings.facebookAppId
          ? <meta content={settings.facebookAppId} property={'fb:app_id'} />
          : ''
      }
      {
        (settings && settings.facebookAdmins && settings.facebookAdmins.trim())
          ? settings.facebookAdmins.trim().split(',').map((admin, index) => {
            return admin.trim()
              ? <meta key={index} content={admin.trim()} property={'fb:admins'} />
              : ''
          })
          : ''
      }
      {
        settings && settings.twitterAccountId
          ? <meta content={settings.twitterAccountId} property={'twitter:account_id'} />
          : ''
      }
      {
        settings && settings.twitterSite
          ? <meta content={settings.twitterSite} property={'twitter:site'} />
          : ''
      }
      {
        settings && settings.twitterCreator
          ? <meta content={settings.twitterCreator} property={'twitter:creator'} />
          : ''
      }

      {RawSEOMeta}

      {
        GOOGLE_ANALITYCS_ID
          ? googleAnalytics
          : null
      }
      <link href="/css/cera-round-pro.css" rel="stylesheet" />
    </BaseHead>
  )
}

Head.propTypes = {
  title: PropTypes.string,
  rawSeo: PropTypes.string,
  settings: PropTypes.object
}

export default Head
